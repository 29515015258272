<template>
  <div class="container">
    <div class="header">
      <div class="left">
        <div class="title">软件中心</div>
        <div class="content">用软件给硬件赋能，通过软件编程去实现虚拟化、
          灵活、多样和定制化的功能，对外提供客户化的专用智能化、
          定制化的服务，实现应用软件与硬件的深度融合、
          系统运行效率和能量效率最大化。</div>
      </div>
      <img class="right" src="../../../static/img/product-center/soft.png"
        alt="">
    </div>
    <div class="footer">
      <div class="card" v-for="(item,i) in cardArr" :key="i">
        <div class="imgwrap"><img
            :src="require('../../../static/img/product-center/' + item.url + '.png')"
            alt=""></div>
        <div class="card-info">
          <div class="card-title">{{item.title}}</div>
          <div class="card-detail">{{item.content}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      cardArr: [
        {
          title: '智慧公寓人脸识别系统',
          content: '采用云平台，大数据分析手段提高公寓日常管理效率... ',
          url: 'card1'
        },
        {
          title: '智慧公寓管理系统',
          content: '实现了人员，公寓管理系统智慧门锁、电控、智能烟感..',
          url: 'card2'
        },
        {
          title: '智慧校园安防系统',
          content: '建立整个校园高清智能视等实时监测监控体系，图形化...',
          url: 'card3'
        },
        {
          title: '智慧迎新管理系统',
          content: '以一卡通或数字化校园数据交换平台为基础信息交换平... ',
          url: 'card4'
        },
        {
          title: '人脸信息大数据库',
          content: '实现百万级人脸库快速查找，毫秒级输出搜索结果，适...',
          url: 'card5'
        },
      ]
    }
  },
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  min-width: 1200px;
  .header {
    // width: 10rem;
    height: 2.1875rem;
    opacity: 1;
    background: #f6f8fb;
    padding: 0.270833rem 1.875rem;
    display: flex;
    justify-content: space-between;
    .left {
      width: 3.015625rem;
      padding-top: 0.270833rem;
      .title {
        font-size: 0.1875rem;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 0.15625rem;
        color: #000000;
        opacity: 0.87;
        padding-bottom: 0.15625rem;
      }
      .content {
        font-size: 0.104167rem;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 0.1875rem;
        color: #000000;
        // letter-spacing: 75px;
        opacity: 0.6;
      }
    }
    .right {
      width: 2.604167rem;
      height: 1.651042rem;
    }
  }
  .footer {
    padding: 0.270833rem 1.875rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &:after {
      content: "";
      width: 2.010417rem;
      display: block;
      height: 0;
      /*只需要添加父元素的after伪元素中 高度0 ，宽度与item的宽一样*/
    }
    .card {
      margin-bottom: 0.208333rem;
      // margin-right: 0.109375rem;
      width: 2.010417rem;
      height: 1.567708rem;
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
      opacity: 1;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .imgwrap {
        width: 100%;
        text-align: center;
        background: #f6f8fb;
        opacity: 1;
        border-radius: 4px 4px 0px 0px;
        padding: 0.083333rem 0 0.046875rem 0;
        // display: felx;
        // justify-content: center;
      }
      img {
        width: 1.447917rem;
        height: 0.807292rem;
        transition: transform 0.5s;
      }
      &:hover img {
        transform: scale(1.4);
      }
      .card-info {
        // height: 121px;
        text-align: center;
        width: 100%;
        .card-title {
          width: 100%;
          font-size: 0.083333rem;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 0.15625rem;
          color: #000000;
          opacity: 0.87;
          padding-top: 0.15625rem;
          padding-bottom: 0.03125rem;
        }
        .card-detail {
          width: 100%;
          font-size: 0.072917rem;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 0.15625rem;
          color: #000000;
          opacity: 0.6;
          padding: 0 0.104167rem;
          // max-width: 1.822917rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>